import React, { useState } from 'react';
import SectionTitle from '../../components/Shared/SectionTitle';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { itSolutionWhatwedo } from '../../common/data';

// import FeatherIcon
// import FeatherIcon from 'feather-icons-react';

// Modal Video
import ModalVideo from 'react-modal-video';
import '../../../node_modules/react-modal-video/scss/modal-video.scss';

// import image
import ab01 from '../../assets/images/course/online/IMG-4.jpg';
import ab02 from '../../assets/images/course/online/IMG-2.jpg';
import ab03 from '../../assets/images/course/online/IMG-3.jpg';
import { Link } from 'react-router-dom';

const WhatWeDo = () => {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          {/* <Row className="justify-content-center">
            <div className="col-12 text-center">
              <SectionTitle
                title="What we do ?"
                desc=" that can provide everything you need to generate awareness, drive traffic, connect"
              />
            </div>
          </Row> */}
          <Row>
            <Container className="mt-100 mt-60">
              <Row className="align-items-center">
                <Col lg={6} md={6}>
                  <Row className="align-items-center">
                    <Col lg={6} className="col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                      <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                        <CardBody className="p-0">
                          <img src={ab01} className="img-fluid" alt="" />
                          <div className="overlay-work bg-dark"></div>
                          <div className="content">
                            <Link
                              to="#"
                              className="title text-white d-block fw-bold"
                            >
                              Lunchtime
                            </Link>
                            {/* <small className="text-light">IT & Software</small> */}
                          </div>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col lg={6} className="col-6">
                      <Row>
                        <Col
                          lg={12}
                          md={12}
                          className="mt-4 mt-lg-0 pt-2 pt-lg-0"
                        >
                          <div className="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                            <div className="card-body p-0">
                              <img src={ab02} className="img-fluid" alt="" />
                              <div className="overlay-work bg-dark"></div>
                              <div className="content">
                                <Link
                                  to="#"
                                  className="title text-white d-block fw-bold"
                                >
                                  Nurturing
                                </Link>
                                {/* <small className="text-light">
                                  Engineering
                                </small> */}
                              </div>
                            </div>
                          </div>
                        </Col>

                        <Col lg={12} md={12} className="mt-4 pt-2">
                          <div className="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                            <div className="card-body p-0">
                              <img src={ab03} className="img-fluid" alt="" />
                              <div className="overlay-work bg-dark"></div>
                              <div className="content">
                                <Link
                                  to="#"
                                  className="title text-white d-block fw-bold"
                                >
                                  Yoga
                                </Link>
                                {/* <small className="text-light">C.A.</small> */}
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col lg={6} md={6} className="mt-4 mt-lg-0 pt- pt-lg-0">
                  <div className="ms-lg-4">
                    <div
                      className="section-title mb-4 pb-2"
                      style={{ textAlign: 'justify' }}
                    >
                      <h4 className="title mb-4">About Our Story</h4>
                      <p className="text-muted para-desc">
                        At{' '}
                        <span className="text-primary fw-bold">
                          VibhArambha
                        </span>{' '}
                        we aim to instill lifelong habits of health,
                        mindfulness, and compassion in our young learners,
                        setting a solid foundation for their future well-being.
                      </p>
                      <p className="text-muted para-desc mb-0">
                        In our Playhome, we host an annual dental camp to
                        promote oral health awareness among our young learners.
                        The dental camp is a fun and educational event where
                        children learn about the importance of brushing,
                        flossing, and maintaining healthy habits for strong
                        teeth. We invite dental professionals to engage with the
                        children through interactive sessions, teaching them how
                        to care for their smiles.<p></p> Alongside this, we
                        incorporate yoga into our curriculum, offering calming
                        and mindful practices to enhance emotional well-being.
                        Through yoga, children develop focus, balance, and
                        relaxation techniques, nurturing both their physical and
                        mental growth. At the heart of our Playhome philosophy
                        is a deep commitment to the holistic well-being of every
                        child, fostering a caring and nurturing environment
                        where they can flourish.
                      </p>
                    </div>

                    <ul className="list-unstyled text-muted">
                      <li className="mb-0">
                        <span className="text-primary h4 me-2">
                          <i className="uil uil-check-circle align-middle"></i>
                        </span>
                        "We prioritize nurturing every child's growth and
                        development."
                      </li>
                      <li className="mb-0">
                        <span className="text-primary h4 me-2">
                          <i className="uil uil-check-circle align-middle"></i>
                        </span>
                        "Smiles Brighten: Our Dental Camp for Happy, Healthy
                        Little Grins!"
                      </li>
                      <li className="mb-0">
                        <span className="text-primary h4 me-2">
                          <i className="uil uil-check-circle align-middle"></i>
                        </span>
                        "Namaste to Growth: Find Balance and Bliss in Our
                        Playhome Yoga Journey!"
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </Row>
          <ModalVideo
            channel="youtube"
            isOpen={open}
            videoId="yba7hPeTSjk"
            onClose={() => setOpen(false)}
          />
        </Container>
      </section>
    </React.Fragment>
  );
};
export default WhatWeDo;
